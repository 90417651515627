<template lang="html">
  <v-container fluid>
    <v-row>
      <v-col cols=12>
        <base-material-card
          class="px-5 py-3"
        >
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              Profil Pegawai
            </div>
          </template>
          <v-card-title>
            <v-row justify="space-between">
              <v-col cols="4" xs="6">
                <v-autocomplete
                  v-model="skpd"
                  label="Pilih SKPD"
                  :items="listSKPD"
                  item-text="nama_skpd"
                  item-value="kode_skpd"
                  >
                </v-autocomplete>
              </v-col>
              <v-col xs="6" cols="3">
                <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                :label="$t('Search')"
                single-line
                hide-details
                style="width:100%"

                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text id="profile-table">
            <v-data-table
              :headers="headers"
              :items="items"
              :server-items-length="totalItems"
              :page.sync="page"
              :items-per-page.sync="itemsPerPage"
              :loading="loading"
              :footer-props="{'items-per-page-options':[5,10,15]}"
            >
            <template v-slot:item.nama="{item}">
              <v-avatar v-if="!DISABLE_TABLE_AVATAR_IMG" size="40" class="mr-2">
                <v-img :src="avatarImages[item.nip]" @error="getImg(item.nip,'jpg')" class="grey lighten-2">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>
              {{item.nama}}
            </template>
            <template v-slot:item.aksi="{ item }">
              <v-btn small color="info" class="ma-1" @click="detail(item)">
                <v-icon small title="detail">
                  mdi-magnify
                </v-icon>
                Detail
              </v-btn>
              <v-btn small color="warning" class="ma-1" @click="addRole(item)">
                <v-icon small title="detail">
                  mdi-plus
                </v-icon>
                Add Role
              </v-btn>
            </template>
          </v-data-table>
        </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>

    <dialog-base></dialog-base>

    <v-dialog v-model="dialogAddRole" max-width="800px" persistent>
      <v-card>
        <v-card-title>
          <span class="headline">Add Role</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="formFormatSKP">
            <v-row align="center">
              <v-col cols="12" sm="2">
                <span class="mx-0">Existing Role</span>
              </v-col>

              <v-col cols="12" sm="10">
                <v-autocomplete
                  :items="listRole"
                  v-model="modelRole"
                  @change="checkRole"
                  item-text="role"
                  item-value="id"
                  label="Available Roles"
                  multiple
                  chips
                  hint="(You can set role for each user in MangBagja)"
                  persistent-hint
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" sm="2">
                <span class="mx-0">PLT</span>
              </v-col>

              <v-col cols="12" sm="10">
                <v-autocomplete
                  :items="listPLT"
                  :disabled="disabledPLT"
                  v-model="modelPLT"
                  item-text="plt"
                  item-value="id"
                  label="Available PLT Roles"
                  multiple
                  chips
                  hint="(You can set role for each user in MangBagja)"
                  persistent-hint
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" sm="2">
                <span class="mx-0">PLT Month</span>
              </v-col>

              <v-col cols="12" sm="10">
                <v-autocomplete
                  :items="listMonth"
                  v-model="month"
                  item-text="text"
                  item-value="id"
                  label="Choose Month For PLT"
                  chips
                  persistent-hint
                ></v-autocomplete>
              </v-col>

            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialogAddRole=false">Tutup</v-btn>
          <v-btn color="success" @click="saveRole()">Simpan</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import settings from '@/utils/settings'
import ProfilePegawaiService from '@/services/ProfilePegawaiService'
import DialogBase from './DialogBase'
import store from '@/store'

export default {
  data(){
    return {
      search:'',
      skpd:'',
      listSKPD:[],
      page:1,
      itemsPerPage:10,
      totalItems:100,
      loading:false,
      avatarImages:{},
      headers:[
        {text:'NIP', value:'nip', sortable:false, class:'header-index-profile'},
        {text:'Nama', value:'nama', sortable:false, class:'header-index-profile'},
        {text:'Jabatan', value:'jabatan', sortable:false, class:'header-index-profile', width:'25%'},
        {text:'Pangkat', value:'pangkat', sortable:false, class:'header-index-profile'},
        {text:'Eselon', value:'eselon', sortable:false, class:'header-index-profile'},
        {text:'Job Value', value:'job value', sortable:false, class:'header-index-profile'},
        {text:'Aksi', value:'aksi', sortable:false, class:'header-index-profile'}],
      items:[],
      dialogAddRole: false,
      listRole: [],
      modelRole: [],
      nip: null,
      listPLT: [],
      modelPLT: [],
      disabledPLT: false,
      listMonth:[
        {
          'text': 'Januari',
          'id':1,
        },
        {
          'text': 'Februari' ,
          'id':2,
        },
        {
          'text': 'Maret' ,
          'id':3,
        },
        {
          'text': 'April' ,
          'id':4,
        },
        {
          'text': 'Mei' ,
          'id':5,
        },
        {
          'text': 'Juni' ,
          'id':6,
        },
        {
          'text': 'Juli' ,
          'id':7,
        },
        {
          'text': 'Agustus' ,
          'id':8,
        },
        {
          'text': 'September' ,
          'id':9,
        },
        {
          'text': 'Oktober' ,
          'id':10,
        },
        {
          'text': 'November' ,
          'id':11,
        },
        {
          'text': 'Desember',
          'id':12,
        }
      ],
      month: null
    }
  },

  computed:{
    DISABLE_TABLE_AVATAR_IMG:function(){
      console.log(settings.DISABLE_TABLE_AVATAR_IMG)
      return settings.DISABLE_TABLE_AVATAR_IMG
    }
  },

  watch:{
    page(val){
      this.updatePegawai()
    },
  },
  components:{
    DialogBase
  },
  methods:{
    checkRole(val){
      // var arr2 = ['25','26','27']
      // if(val.some(r=> arr2.includes(r))){
        this.disabledPLT = false
      // }else{
      //   this.disabledPLT = true
      // }
    },
    saveRole(){
      var temp_ = []
      this.modelRole.forEach(el => {
        var id = parseInt(el)
        this.listRole.forEach(elRole => {
          var id_ = parseInt(elRole.id)
          if(id_ == id){
            temp_.push(elRole.role)
          }
        });
      });
      if(this.modelPLT.length > 1){
        // return store.commit('snackbar/setSnack',{message: 'Maaf, saat ini hanya bisa 1 role PLT untuk 1 pegawai.', color:'false'})
      }
      this.modelRole = temp_
      ProfilePegawaiService.storeRole(this.nip, this.modelRole).then(response => {
        let res = response.data
        if (res.status == 200){
          store.commit('snackbar/setSnack',{message: res.message, color:'success'})
        } else {
          store.commit('snackbar/setSnack',{message: res.message, color:'error'})
        }
      })

      if(this.modelPLT[0] != undefined){
        if(this.modelPLT[0].id != undefined){
          this.modelPLT[0] = this.modelPLT[0].id
        }
      }
      ProfilePegawaiService.storePLT(this.nip, this.modelPLT, this.month).then(response => {
        let res = response.data
        if (res.status == 200){
          store.commit('snackbar/setSnack',{message: res.message, color:'success'})
        } else {
          store.commit('snackbar/setSnack',{message: res.message, color:'error'})
        }
      })

      this.dialogAddRole = false
      this.search = ""
      this.updatePegawai()
    },
    addRole(item){
      this.nip = item.nip
      ProfilePegawaiService.getUserRole(item.nip).then(response => {
        var temp_ = []
        this.listRole.forEach(el => {
          var role = el.role
          var id = el.id
          response.data.role.forEach(elRole => {
            if(elRole == role){
              temp_.push({
                "id": id,
                "role": role,
              })
            }
          });
        });
        this.modelRole = temp_
      })

      ProfilePegawaiService.getUserPLTRole(item.nip).then(response => {
        var temp_ = []
        response.data.data.forEach(elPLT => {
          var id_ = elPLT.plt_id.split("-")
          this.listPLT.forEach(el => {
            var plt = el.plt
            var id = el.id
            if(parseInt(id_[1]) == id){
              temp_.push({
                "id": id,
                "plt": plt,
              })
            }
          });
        });
        this.modelPLT = temp_
      })
      this.dialogAddRole = true
    },
    detail(item){
      store.commit('dialog/SET_PROFILE', {profile:true, nip:item.nip})
    },
    enableImages(){
      return true
    },
    updatePegawai(){
      this.loading = true
      ProfilePegawaiService.listPegawai({skpd:this.skpd,cari:this.search,row_per_page:this.itemsPerPage,page:this.page}).then(response=>{
        this.loading = false
        // konversi string menjadi integer
        this.totalItems=response.data.total_keseluruhan*1
        this.items = response.data.data
        for (let i = 0; i < this.items.length; i++){
          let nip = this.items[i].nip
          this.getImg(nip, 'jpeg')
        }
      })
    },
    getImg(nip,ext){
      let avatarImgUrl = process.env.VUE_APP_IMG_URL + nip +"."+ext
      this.avatarImages[nip] = avatarImgUrl
      return avatarImgUrl
    }
  },
  created(){
    ProfilePegawaiService.listSKPD().then(response=>{
      this.listSKPD = response.data
    })
    ProfilePegawaiService.getListRole().then(response => {
      this.listRole = response.data
    })
    ProfilePegawaiService.getListPLT().then(response => {
      this.listPLT = response.data
    })
    // var arr2 = ['25','26','27']
    // if(this.modelRole.some(r=> arr2.includes(r))){
      this.disabledPLT = false
    // }else{
      // this.disabledPLT = true
    // }
    this.month = null
  },
  mounted(){
    this.$watch(vm => [vm.skpd, vm.itemsPerPage, vm.search], val =>{
      // reset halaman, kembali ke halaman satu
      this.page = 1
      // update data pegawai
      this.updatePegawai()
    },{immediate:true, deep:false})
  }
}
</script>

<style lang="scss">
</style>
