<template lang="html">
  <v-container>
    <h3 class="text-h3">Hasil Individual Assessment</h3>
    <v-row>
      <v-col><div id="radar-hasil" style="height:300px"></div></v-col>
      <v-col cols="12">
        <v-data-table :headers="headersKompetensi" :items="kompetensi" class="striped" disable-sort disable-pagination hide-default-footer>
          <template slot="body.append">
            <tr>
              <td colspan="9">Poin</td>
              <td>{{poin}}</td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <h3 class="text-h3">Hasil Scoring</h3>
    <v-row>
      <v-col cols="12">
        <v-data-table :headers="headersScoring" :items="scoringData" class="striped" disable-sort disable-pagination hide-default-footer>
          <template v-slot:item.tingkat_kemahiran="{item}">
            <v-rating :value="item.tingkat_kemahiran * 1" color="yellow darken-3" background-color="grey darken-1" empty-icon="$ratingFull" readonly></v-rating>
          </template>
          <template slot="body.append">
            <tr>
              <td colspan="2">Hasil: (Total Skor)</td>
              <td><b>{{ total.skj }}</b></td>
              <td><b>{{ total.gap }}</b></td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import echarts from 'echarts'
export default {
  name:"Hasil",
  props:{
    scoringData:{
      type: Array,
      default:()=>[]
    },
    scoringAssessment:Array
  },
  data(){
    return {
      radarChart:null,
      poin:0,
      headersKompetensi:[{
        text:'Penilai',
        value:'name',
        width:'150px'
      },{
        text:'Integritas',
        value:'integritas'
      },{
        text:'Kerjasama',
        value:'kerjasama'
      },{
        text:'Komunikasi',
        value:'komunikasi'
      },{
        text:'Orientasi pada Hasil',
        value:'orientasi pada hasil'
      },{
        text:'Pelayanan Publik',
        value:'pelayanan publik'
      },{
        text:'Pengembangan Diri dan Organisasi',
        value:'pengembangan diri dan orang lain'
      },{
        text:'Mengelola Perubahan',
        value:'mengelola perubahan'
      },{
        text:'Pengambilan Keputusan',
        value:'pengambilan keputusan'
      },{
        text:'Perekat Bangsa',
        value:'perekat bangsa'
      }],
      kompetensi:[],
      headersScoring:[{
        text:'Deskripsi',
        value:'deskripsi'
      },{
        text:'Tingkat Kemahiran',
        value:'tingkat_kemahiran'
      },{
        text:'SKJ',
        value:'skj'
      },{
        text:'GAP',
        value:'gap'
      }]
    }
  },
  computed:{
    total(){
      let t_skj=0
      let t_gap=0
      for(let i = 0; i < this.scoringData.length; i++){
        let tempSKJ = this.scoringData[i].skj || 0
        let tempGAP = this.scoringData[i].gap || 0
        t_skj+= tempGAP * 1
        t_gap+= tempSKJ * 1
      }
      return {skj:t_skj,gap:t_gap}
    }
  },
  mounted(){
    let assesments = []
    let formatedData=[{name:'atasan'},{name:'rekan'},{name:'bawahan'},{name:'diri sendiri'}]
    let poinParsed = [{name:'atasan', total:0}, {name:'rekan',total:0},{name:'bawahan',total:0},{name:'diri sendiri',total:0}]
    let poinObject = {}
    this.radarChart = echarts.init(document.getElementById('radar-hasil'))

    // parsing data menyesuaikan dengan format tabel
    for(let i =0; i < this.scoringAssessment.length; i++){
      let temp = this.scoringAssessment[i]

      let idx=-1

      if (temp.penilai === 'atasan'){
        idx = formatedData.map(x => x.name).indexOf('atasan')
      }else if (temp.penilai === 'bawahan') {
        idx = formatedData.map(x => x.name).indexOf('bawahan')
      }else if (temp.penilai === 'diri sendiri') {
        idx = formatedData.map(x => x.name).indexOf('diri sendiri')
      }else if (temp.penilai === 'rekan sejawat') {
        idx = formatedData.map(x => x.name).indexOf('rekan')
      }
      formatedData[idx][temp.kelompok.toLowerCase()] = Math.floor(temp['jumlah'] * 100) /100

      // perhitungan poin
      poinParsed[idx]['total'] += Math.floor(temp['jumlah'] * 100) /100
    }
    // perhitungan poin sementara menggunakan rata-rata total atasan dan sisanya
    // dengan rumus a * 60 + b * 40 dimana ; a = atasan, b = sisanya
    // ubah menjadi object
    for (let i =0; i < poinParsed.length; i++){
      poinObject[poinParsed[i].name] = poinParsed[i].total
    }
    this.poin = poinObject.atasan * 60/100 + (poinObject.rekan + poinObject.bawahan + poinObject['diri sendiri']) * 40 /100

    // set table values
    this.kompetensi = formatedData

    this.setRadarOption()
  },
  methods:{
    setRadarOption(){
      let indicator = [];
      let parsedData = [];

      // buat header radar
      for(let i = 1; i< this.headersKompetensi.length;i++){
        indicator.push({name:this.headersKompetensi[i].text,max:100})
      }
      // parsing data
      for (let i = 0; i < this.kompetensi.length;i++){
        let temp = this.kompetensi[i]
        parsedData[temp.name.toLowerCase()]=[
          temp.integritas,
          temp.kerjasama,
          temp.komunikasi,
          temp['orientasi pada hasil'],
          temp['pelayanan publik'],
          temp['pengembangan diri dan orang lain'],
          temp['mengelola perubahan'],
          temp['pengambilan keputusan'],
          temp['perekat bangsa']]
      }
      let option = {
          tooltip: {},
          legend: {
            data: ["Atasan", "Bawahan", "Rekan", "Diri Sendiri"],
            orient: "vertical",
            right: 0
          },
          radar: {
            indicator: indicator
          },
          series: [{
            name: "Nilai Review",
            type: "radar",
            areaStyle:{},
            data: [{
              value: parsedData['atasan'],
              name: "Atasan"
            },{
              value: parsedData['bawahan'],
              name: "Bawahan"
            },{
              value: parsedData['rekan'],
              name: "Rekan"
            },{
              value: parsedData['diri sendiri'],
              name: "Diri Sendiri"
            }
          ]
          }]
        }
      this.radarChart.setOption(option)
    }
  }
}
</script>

<style lang="css" scoped>
</style>
