<template lang="html">
 <v-container>
   <h1 class="text-h3">Grid</h1>

   <div class="d-flex justify-center">
    <div v-for="(order, index) in [[4,2,1],[7,5,3],[9,8,6]]" :key="index">

      <div class="flip-card mt-2 mr-3" v-for="i in order" :key="i" @click="sheetMsg=grid[i].keterangan;sheet=true">
        <div class="flip-card-inner">
          <!-- kartu depan -->
          <div :class="{blue:chosen != i, green:chosen == i}"
            class="flip-card-front blue text-h3 white--text d-flex">
            <span style="position:absolute;font-size:100px; top:45%;left:40%;color:rgba(255,255,255,0.3)">{{i}}</span>
            <p class="mx-auto my-auto" v-html="grid[i].syarat"></p>
          </div>
          <!-- kartu belakang -->
          <div :class="{blue:chosen != i, green:chosen == i}"
            class="flip-card-back d-flex darken-1 body-1" >
            <div class="my-auto mx-auto px-3">
              <ul class="mb-3">
                <li v-for="(item, index) in grid[i].isi" :key="index" v-html="item.replaceAll('\'','')"></li>
              </ul>
              <span class="red--text" v-html="grid[i].note"></span>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
   <v-bottom-sheet v-model="sheet" inset>
     <v-sheet class="text-center" height="200px" style="overflow:auto">
       <div class="my-3 text-left pr-4 pl-4" v-html="sheetMsg"></div>
       <v-btn
       class="mt-1 mb-2"
       text
       color="error"
       @click="sheet = !sheet"
       >{{$t('close')}}</v-btn>
     </v-sheet>
   </v-bottom-sheet>

   <h1 class="text-h3 mt-6">Analisa Kesenjangan Kompetensi dan Kinerja</h1>
   <v-data-table :headers="headers" :items="items"></v-data-table>
   </v-container>
</template>

<script>
import _ from 'lodash'
export default {
  name:"Kebutuhan",
  props:{
    gridData:Array,
    kesenjanganData:Array
  },
  data(){
    return{
      sheetMsg:'',
      sheet:false,
      chosen:8,
      headers:[{
        text: 'Nilai Kompetensi ASN',
        value: 'nilai_kompetensi_asn'
      },{
        text:'Analisa Kesenjangan',
        value:'analisa_kesenjangan'
      },{
        text:'Kinerja',
        value:'kinerja'
      },{
        text:'Kesenjangan Kinerja',
        value:'kesenjangan_kinerja'
      },{
        text:'Kebutuhan Pengembangan',
        value:'kebutuhan_pengembangan'
      }],
      items:[],
      grid:{
        1:{syarat: 'Kinerja <strong>0 s.d 50 </strong>dan kompetensi <strong>0 s.d 71'},
        2:{syarat: 'Kinerja <strong>51 s.d 85</strong> dan kompetensi <strong>0 s.d 71</strong>'},
        3:{syarat: 'Kinerja <strong>0 s.d 50</strong> dan kompetensi <strong>72 s.d 96</strong>'},
        4:{syarat: 'Kinerja <strong>&gt;85</strong> dan kompetensi <strong>0 s.d 71 </strong>'},
        5:{syarat: 'Kinerja <strong>51 s.d 85</strong> dan kompetensi <strong>72 s.d 96</strong>'},
        6:{syarat: 'Kinerja <strong>0 s.d 50</strong> dan kompetensi <strong>&ge;97</strong>'},
        7:{syarat: 'Kinerja <strong>&gt;85</strong> dan kompetensi <strong>72 s.d 96</strong>'},
        8:{syarat: '<p>Kinerja <strong>51 s.d 85</strong> dan kompetensi <strong>&ge;97</strong>'},
        9:{syarat: 'Kinerja <b>&gt;85</b> dan kompetensi <b> &ge;97 </b>'}
      }// grid
    } // return
  },// data
  mounted(){
    // penambahan data pada grid
    for (let key in this.grid){
      let idx = this.gridData.map(x=>x.nomor_grid).indexOf(key*1)
      let cloned = _.clone(this.grid[key])
      let temp = {...cloned, ...this.gridData[idx] }
      this.grid[key] = _.clone(temp)
    }

    // set nilai pada tabel
    this.items = this.kesenjanganData

    let temp = this.items[0]

    // penempatan grid
    for (let key in this.grid){
      if (temp['kinerja'] >= this.grid[key].nilai_kinerja_min &&
          temp['kinerja'] <= this.grid[key].nilai_kinerja_max &&
          temp['nilai_kompetensi_asn'] >= this.grid[key].nilai_kompetensi_min &&
          temp['nilai_kompetensi_asn'] <= this.grid[key].nilai_kompetensi_max
          ){
            this.chosen = key
            break
          }
    }

  }
}
</script>
<style lang="scss" scoped>
.table-grid{
  width:100%;
  border-collapse: collapse;

  td{
    max-width: 200px;
    border: 1px solid #ddd;

    &.content{
      padding:20px 5px 10px 5px;
      height:250px;
      overflow:auto;
      cursor: help;

      &:hover{
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
      }
    }


    div{
      height:100%;
    }
  }

  .none {
    border:none;
  }

  .center{
    text-align:center
  }
}
.vertical{
  transform:rotate(270deg);
  // writing-mode: vertical-rl;
  // text-orientation: sideways;
}

.flip-card {
  background-color: transparent;
  width: 250px;
  height: 250px;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  color: black;
  text-align:center;
  vertical-align: middle;

}

.flip-card-back {
  color: white;
  transform: rotateY(180deg);
}
</style>
