<template lang="html">
    <v-dialog
       v-model="dialog"
       max-width="1000px"
       persistent
       scrollable
      >
       <v-card color="primary" dark v-if="loading1 || loading2 || loading3 || loading4 || loading5 || loading6 || loading7 || loading8 || loading9 || loading10">
        <v-card-text class="white--text">
          Harap tunggu sebentar, mengambil data;
          <span v-if="loading1">data pegawai ...</span>
          <span v-if="loading2">nilai review ...</span>
          <span v-if="loading3">kehadiran ...</span>
          <span v-if="loading4">hukuman disiplin ...</span>
          <span v-if="loading5">target kinerja ...</span>
          <span v-if="loading6">detail kinerja ...</span>
          <span v-if="loading7">assesment ...</span>
          <span v-if="loading8">hasil scoring ...</span>
          <span v-if="loading9">kebutuhan ...</span>
          <span v-if="loading10">analisa kesenjangan ...</span>
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
       </v-card>
       <v-card v-else>
         <v-card-title class="pa-0">
           <v-toolbar flat>
             <v-toolbar-title>Profil Kinerja Pegawai</v-toolbar-title>
             <v-spacer></v-spacer>
             <v-btn icon @click="close"><v-icon>mdi-close</v-icon></v-btn>
           </v-toolbar>
         </v-card-title>
         <hr>
         <v-card-text style="height:calc( 100vh - 100px )">
         <v-container>
           <v-row>
             <v-col cols="9">
               <p class="text-body-1" >
                 <span class="label-data">Nama</span>:<span class="profil-text-data font-weight-medium">{{pegawai.nama}}</span> <br/>
                 <span class="label-data">NIP</span>:<span class="profil-text-data font-weight-medium">{{pegawai.nip}}</span> <br/>
                 <span class="label-data">Jabatan</span>:<span class="profil-text-data font-weight-medium">{{pegawai['jabatan']}}</span> <br/>
                 <span class="label-data">Unit Kerja</span>:<span class="profil-text-data font-weight-medium">{{pegawai['unit kerja']}}</span><br/>
                 <span class="label-data">Perangkat Daerah</span>:<span class="profil-text-data font-weight-medium">{{pegawai['perangkat daerah']}}</span> <br/>
                 <span class="label-data">Lama Menjabat</span>:<span class="profil-text-data font-weight-medium">{{pegawai['lama menjabat']}}</span> <br/>
               </p>
             </v-col>
             <v-col cols="3">
               <v-img :src="avatarPhoto" @error="replacePhoto(pegawai.foto)" width="150px" height="200px" class="grey lighten-2">
                 <template v-slot:placeholder>
                   <v-row
                     class="fill-height ma-0"
                     align="center"
                     justify="center"
                   >
                     <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                   </v-row>
                 </template>
               </v-img>
             </v-col>
           </v-row>
         </v-container>
         <v-tabs
            v-model="tab"
            background-color="primary"
            dark
            grow
            show-arrows
          >
            <v-tab>Analisa Kesenjangan Pegawai</v-tab>
            <v-tab>Nilai Review</v-tab>
            <v-tab>Kehadiran</v-tab>
            <v-tab>Target Kinerja</v-tab>
            <v-tab>Hasil Assesment dan Scoring</v-tab>
            <v-tab>Kebutuhan Pengembangan</v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">

            <v-tab-item>
              <analisa
                :analisaData="{analisa:analisaKesenjanganKinerja, dialog:dataDetail.dialog_kinerja}"
               ></analisa>
            </v-tab-item>

            <v-tab-item>
              <nilai-review :reviews="reviews"></nilai-review>
            </v-tab-item>

            <v-tab-item>
              <kehadiran :izinData="kehadiranIzin" :hukumanData="kehadiranHukuman"></kehadiran>
            </v-tab-item>

            <v-tab-item>
              <target :targetData="targetData" :detailData="targetDetail"></target>
            </v-tab-item>

            <v-tab-item>
              <hasil :scoringData="scoring" :scoringAssessment="scoringAssessment"></hasil>
            </v-tab-item>

            <v-tab-item>
              <kebutuhan :gridData="kebutuhanGrid" :kesenjanganData="kebutuhanKesenjangan"></kebutuhan>
            </v-tab-item>

          </v-tabs-items>
          </v-card-text>
       </v-card>
     </v-dialog>

</template>

<script>
import _ from 'lodash'
import ProfilePegawaiService from '@/services/ProfilePegawaiService'
import store from '@/store'
import { mapState } from 'vuex'
import Analisa from './components/Analisa'
import Hasil from './components/Hasil'
import Kebutuhan from './components/Kebutuhan'
import Kehadiran from './components/Kehadiran'
import NilaiReview from './components/NilaiReview'
import Target from './components/Target'


export default {
  name:'DialogBase',
  components:{
    Analisa,
    Hasil,
    Kebutuhan,
    Kehadiran,
    NilaiReview,
    Target
  },
  data(){
    return {
      loading:true,
      tab: null,
      pegawai:{},
      dataDetail:{},
      avatarPhoto:'',
      scoring:[],
      scoringAssessment:[],
      targetData:{},
      targetDetail:[],
      reviews:[],
      kebutuhanGrid:[],
      kebutuhanKesenjangan:[],
      kehadiranIzin:[],
      kehadiranHukuman:[],
      loading1:false,
      loading2:false,
      loading3:false,
      loading4:false,
      loading5:false,
      loading6:false,
      loading7:false,
      loading8:false,
      loading9:false,
      loading10:false,
      analisaKesenjanganKinerja:{}
    }
  },
  computed:{
    dialog(){
      return store.state.dialog.profile
    },
    ...mapState({
      nip: state => state.dialog.nip
    })
  },
  watch:{
    nip(val){
      this.fetchDetail()
    },
  },
  mounted(){
    this.fetchDetail()
  },
  beforeDestroy(){
    // reset files
    this.dataDetail={}
    this.pegawai={}
  },
  methods:{
    close(){
      store.commit('dialog/SET_PROFILE',{profile:false})
    },
    replacePhoto(url){
      this.avatarPhoto = url
    },
    fetchDetail(){
      this.loading=true
      this.loading1 = true
      this.loading2 = true
      this.loading3 = true
      this.loading4 = true
      this.loading5 = true
      this.loading6 = true
      this.loading7 = true
      this.loading8 = true
      this.loading9 = true
      this.loading10 = true


      // analisa kesenjangan pegawai
      ProfilePegawaiService.pegawai({nip:this.nip}).then(response=>{
        let pegawaiData = response.data
        this.pegawai = pegawaiData.pegawai[0]
        this.avatarPhoto = this.pegawai.foto.replace(/\.jpg$/ig,'.jpeg')
        this.dataDetail = _.cloneDeep(pegawaiData)
      }).catch(()=>{
        this.close()
      }).finally(()=>{
        this.loading1=false
      })

      if(this.nip != ''){
      ProfilePegawaiService.getAnalisaKesenjanganKinerja({nip:this.nip}).then(response=>{
        this.analisaKesenjanganKinerja = response.data
      }).catch(()=>{
        this.close()
      }).finally(()=>{
        this.loading1=false
      })
      }

      // nilai review
      ProfilePegawaiService.nilaiReview({nip:this.nip}).then(response=>{
        this.reviews = response.data.data
      }).catch(()=>{
        this.close()
      }).finally(()=>{
        this.loading2=false
      })

      // kehadiran
      ProfilePegawaiService.kehadiran({nip:this.nip}).then(response=>{
        this.kehadiranIzin = response.data.data
      }).catch(()=>{
        this.close()
      }).finally(()=>{
        this.loading3 =false
      })
      ProfilePegawaiService.hukumanDisiplin({nip:this.nip}).then(response=>{
        this.kehadiranHukuman = response.data.data
      }).catch(()=>{
        this.close()
      }).finally(()=>{
        this.loading4 =false
      })

      // target kinerja
      ProfilePegawaiService.targetKinerja({nip:this.nip}).then(response=>{
        this.targetData = response.data.data[0]
      }).catch(()=>{
        this.close()
      }).finally(()=>{
        this.loading5 = false
      })
      ProfilePegawaiService.targetKinerjaDetail({nip:this.nip}).then(response=>{
        this.targetDetail = response.data.data
      }).catch(error=>{
        this.close()
      }).finally(()=>{
        this.loading6 = false
      })

      // hasil asssment
      ProfilePegawaiService.assesment({nip:this.nip}).then(response => {
        this.scoringAssessment = response.data.data
      }).catch(()=>{
        this.close()
      }).finally(()=>{
        this.loading7 = false
      })

      ProfilePegawaiService.hasilScoring({nip:this.nip}).then(response=>{
        this.scoring = response.data.data
      }).catch(error=>{
        this.close()
      }).finally(()=>{
        this.loading8=false
      })

      // kebutuhan pengembangan
      ProfilePegawaiService.grid({nip:this.nip}).then(response=>{
        this.kebutuhanGrid = response.data.data
      }).catch(error=>{
        this.close()
      }).finally(()=>{
        this.loading9=false
      })
      ProfilePegawaiService.analisaKesenjangan({nip:this.nip}).then(response=>{
        this.kebutuhanKesenjangan = response.data.data
      }).catch(error=>{
        this.close()
      }).finally(()=>{
        this.loading10=false
      })
    }
  }

}
</script>

<style lang="scss">
  .label-data {
    display:inline-block;
    width:200px;
    padding:5px;
  }
  .profil-text-data{
    padding:5px;
    font-weight:400;
    display:inline-block;
  }

</style>
