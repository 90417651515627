<template lang="html">
  <v-container>
    <h3 class="text-h3">Kehadiran</h3>
    <v-row >
      <v-col cols="12">
        <div id="pie-kehadiran" style="height:300px"></div>
      </v-col>
    </v-row>
    <h3 class="text-h3">Hukuman Disiplin</h3>
    <v-col cols="12">
      <v-data-table :headers="headers" :items="hukumanData" class="striped" disable-sort disable-pagination hide-default-footer></v-data-table>
    </v-col>
  </v-container>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import echarts from 'echarts'

export default {
  name:"Kehadiran",
  props:{
    izinData:Array,
    hukumanData:Array
  },
  data(){
    return {
      piechart:null,
      kehadiran:{
        izin:5,
        sakit:1,
        cuti:3,
        cutiSakit:1,
        cutiTahunan:5,
        cutiBesar: 1,
        cutiAlasan: 0,
        dinasLuar: 8,
        terlambat:0,
        tanpaKeterangan:0
      },
      headers:[{
          text: 'No. S.K.',
          value: 'no_sk',
        },{
          text: 'Tanggal S.K.',
          align: 'left',
          value: 'tgl_sk'
        },{
          text: 'Jenis Hukuman',
          align: 'left',
          sortable: false,
          value: 'jenis_hukuman'
        },{
          text: 'Keterangan',
          align: 'left',
          sortable: false,
          value: 'keterangan'
        }],
    }
  },
  filters:{
    toText:function(val){
      return _.startCase(val)
    }
  },
  mounted(){
    let pieData = []

    // parseing izin data menyesuaikan dengan data untuk chart
    _.forEach(this.izinData[0], function(value, key){
      if (!['rata2_absen_datang','rata2_absen_pulang'].includes(key)){
        pieData.push({name:_.startCase(key), value: value *1})
      }
    })
    echarts.init(document.getElementById('pie-kehadiran')).setOption({
      legend:{
        orient:"vertical",
        left:'left'
      },
      tooltip:{},
      series: {
          type: 'pie',
          data: pieData,
          // minShowLabelAngle:5,
          radius:[0,'75%']
      }
    });
  }

}
</script>

<style lang="css" scoped>
</style>
