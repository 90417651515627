<template lang="html">
  <v-container>
    <h3 class="text-h3">Nilai Review</h3>
    <v-row justify="center">
      <v-col cols="12">
        <div id="radar-nilai-review" style="height:300px"></div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table :headers="headers" :items="dataReview" class="elevation-1 striped" hide-default-footer>
          <template slot="items" slot-scope="props">
            <td>{{ props.item.name }}</td>
            <td class="text-xs-right">{{ props.item.disiplin }}</td>
            <td class="text-xs-right">{{ props.item.integritas }}</td>
            <td class="text-xs-right">{{ props.item.kepemimpinan }}</td>
            <td class="text-xs-right">{{ props.item.kerjasama }}</td>
            <td class="text-xs-right">{{ props.item.komitmen }}</td>
            <td class="text-xs-right">{{ props.item.orientasi }}</td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import echarts from 'echarts'

export default {
  name:"NilaiReview",
  props:{
    reviews: Array
  },
  data(){
    return {
      radarChart:null,
      headers: [{
          text: 'Penilaian',
          align: 'left',
          sortable: false,
          value: 'name'
        },{
          text: 'Disiplin Kerja',
          value: 'disiplin kerja'
        },{
          text: 'Integritas',
          value: 'integritas'
        },{
          text: 'Kepemimpinan',
          value: 'kepemimpinan'
        },{
          text: 'Kerjasama',
          value: 'kerjasama'
        },{
          text: 'Komitmen',
          value: 'komitmen'
        },{
          text: 'orientasi Pelayanan',
          value: 'orientasi pelayanan'
        }
      ],
      dataReview: []
    }
  },
  mounted(){
    let review={}
    let formatedData=[{name:'atasan'},{name:'rekan'},{name:'bawahan'},{name:'diri sendiri'}]

    this.radarChart = echarts.init(document.getElementById('radar-nilai-review'))

    // parsing data menyesuaikan dengan format tabel
    for(let i =0; i < this.reviews.length; i++){
      let temp = this.reviews[i]
      let idx=-1
      if (temp.hubungan === 'atasan'){
        idx = formatedData.map(x => x.name).indexOf('atasan')
      }else if (temp.hubungan === 'bawahan') {
        idx = formatedData.map(x => x.name).indexOf('bawahan')
      }else if (temp.hubungan === 'pribadi') {
        idx = formatedData.map(x => x.name).indexOf('diri sendiri')
      }else if (temp.hubungan === 'teman') {
        idx = formatedData.map(x => x.name).indexOf('rekan')
      }
      formatedData[idx][temp.kelompok.toLowerCase()] = Math.floor(temp['nilai_review'] * 100) /100
    }

    // set data table
    this.dataReview = formatedData
    for (let i =0; i < this.dataReview.length; i++){
      let temp = this.dataReview[i]
      review[temp.name.toLowerCase()]=[temp['disiplin kerja'],temp.integritas, temp.kepemimpinan, temp.kerjasama, temp.komitmen, temp['orientasi pelayanan']]
    }

    this.setRadarOption(review)
  },
  methods:{
    setRadarOption(review){
      let option = {
          tooltip: {},
          legend: {
            data: ["Atasan", "Bawahan", "Rekan", "Diri Sendiri"],
            orient: "vertical",
            right: 0
          },
          radar: {
            indicator: [{
              name: "Disiplin Kerja",
              max: 100
            }, {
              name: "Integritas",
              max: 100
            }, {
              name: "Kepemimpinan",
              max: 100
            }, {
              name: "Kerjasama",
              max: 100
            }, {
              name: "Komitmen",
              max: 100
            }, {
              name: "Orientasi Pelayanan",
              max: 100
            }]
          },
          series: [{
            name: "Nilai Review",
            type: "radar",
            areaStyle:{},
            data: [{
              value: review['atasan'],
              name: "Atasan"
            },{
              value: review['bawahan'],
              name: "Bawahan"
            },{
              value: review['rekan'],
              name: "Rekan"
            },{
              value: review['diri sendiri'],
              name: "Diri Sendiri"
            }
          ]
          }]
        }
      this.radarChart.setOption(option)
    }
  }
}
</script>

<style lang="scss">
  .striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, .05);
  }
</style>
