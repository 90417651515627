<template lang="html">
  <v-container>
    <h4 class="text-h4">Analisa Kesenjangan Kinerja</h4>
    <v-row justify="center">
      <v-col cols="12" style="overflow-y:auto">
        <div id="analisa-bar-chart" style="height:300px;width:900px;"></div>
      </v-col>
      <v-col cols="12" style="margin-top: -80px">
        <v-expand-transition>
            <div v-show="isTableShown">
              <h1 class="text-h5">{{triwulanTitle}}</h1>
              <v-data-table :headers="headersTriwulan" :items="itemsTriwulan" :footer-props="{'items-per-page-options':[5,10,15]}"></v-data-table>
            </div>
        </v-expand-transition>
      </v-col>
      <v-col cols="12">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr class="blue-grey lighten-4 font-weight-bold" align="center">
                <td colspan=8>Summary Target Dan Realisasi</td>
              </tr>
              <tr class="blue-grey lighten-4 font-weight-medium" align="center">
                <td colspan=2>Triwulan I</td>
                <td colspan=2>Triwulan II</td>
                <td colspan=2>Triwulan III</td>
                <td colspan=2>Triwulan IV</td>
              </tr>
              <tr class="blue-grey lighten-4 font-weight-medium">
                <td>Target</td>
                <td>Realisasi</td>
                <td>Target</td>
                <td>Realisasi</td>
                <td>Target</td>
                <td>Realisasi</td>
                <td>Target</td>
                <td>Realisasi</td>
              </tr>
            </thead>
            <tbody>
                  <template v-for="(data,k) in data_kesenjangan_kinerja.summary_target_realisasi" :load="log(data)">
                    <tr v-bind:key="k" class="blue-grey lighten-5 font-weight-medium">
                      <td>{{data.target_triwulan_1 != 0 ? data.target_triwulan_1 : ''}}</td>
                      <td>{{data.realisasi_triwulan_1 != 0 ? data.realisasi_triwulan_1 : ''}}</td>
                      <td>{{data.target_triwulan_2 != 0 ? data.target_triwulan_2 : ''}}</td>
                      <td>{{data.realisasi_triwulan_2 != 0 ? data.realisasi_triwulan_2 : ''}}</td>
                      <td>{{data.target_triwulan_3 != 0 ? data.target_triwulan_3 : ''}}</td>
                      <td>{{data.realisasi_triwulan_3 != 0 ? data.realisasi_triwulan_3 : ''}}</td>
                      <td>{{data.target_triwulan_4 != 0 ? data.target_triwulan_4 : ''}}</td>
                      <td>{{data.realisasi_triwulan_4 != 0 ? data.realisasi_triwulan_4 : ''}}</td>
                    </tr>
                  </template>

              <!-- <tr class="blue-grey lighten-5 font-weight-medium">
                <td>Target</td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr class="blue-grey lighten-4 font-weight-medium">
                <td>Realisasi</td>
                <td></td>
                <td></td>
                <td></td>
              </tr> -->
              <!-- <tr v-for="(a, b) in analisaData.triwulan_1.skp" :key="b" class="blue-grey lighten-4 font-weight-medium">
                <td></td>
                <td>a.target</td>
                <td>b.realisasi</td>
                <td>Triwulan III</td>
                <td>Triwulan IV</td>
              </tr> -->
              <!-- <tr class="blue-grey lighten-5 font-weight-medium">
                <td>Target</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr class="blue-grey lighten-4 font-weight-medium">
                <td>Realisasi</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr> -->
              <!-- <tr v-if="opini.length > 0">  
                <td>{{opini[0].opini_akhir}}</td>
                <td>{{opini[0].kekuatan}}</td>
                <td>{{opini[0].kekurangan}}</td>
                <td>{{opini[0].tanggapan}}</td>
                <td>{{Math.floor(opini[0].nilai_kinerja*100)/100}}</td>
                <td>{{Math.floor(opini[0].nilai_review_perilaku*100)/100}}</td>
              </tr>
              <tr v-else>
                <td colspan="6" class="text-center">Tidak ada data</td>
              </tr>
              <tr class="blue-grey lighten-4 font-weight-medium">
                <td></td>
                <td colspan="4">Total Kinerja</td>
                <td>???</td>
              </tr>
              <tr class="blue-grey lighten-5 font-weight-medium">
                <td></td>
                <td colspan="4">Review Perilaku</td>
                <td>???</td>
              </tr>
              <tr class="blue-grey lighten-4 font-weight-medium">
                <td></td>
                <td colspan="4">Nilai Akhir</td>
                <td>{{ nilaiAkhir }}</td>
              </tr>
              <tr class="blue-grey lighten-5 font-weight-medium">
                <td></td>
                <td colspan="3">Gap Kinerja</td>
                <td colspan="2" style="text-align:right" :class="{'red--text':gapkinerja === 'tinggi', 'orange--text text--lighten-3':gapkinerja==='rendah', 'yellow--text text--lighten-1':gapkinerja === 'sedang','green--text':gapkinerja === 'tidak ada kesenjangan', 'font-weight-medium':true, 'lighten-2':true}">{{gapkinerja.toUpperCase()}}</td>
              </tr> -->
            </tbody>
          </template>
        </v-simple-table>

        <v-simple-table class="mt-4 elevation-1">
          <template v-slot:default>
            <tbody>
              <tr class="blue-grey lighten-4 font-weight-bold"><td>Evaluasi Kinerja</td></tr>
              <tr class="blue-grey lighten-5 font-weight-medium"><td>Triwulan I</td></tr>
              <template v-for="(data,k) in data_kesenjangan_kinerja.triwulan_1.skp" :load="log(data)">
                <tr v-bind:key="k">
                  <td>{{data.evaluasi_analisis_kinerja}}</td>
                </tr>
              </template>
              <tr class="blue-grey lighten-5 font-weight-medium"><td>Triwulan II</td></tr>
              <template v-for="(data,k) in data_kesenjangan_kinerja.triwulan_2.skp" :load="log(data)">
                <tr v-bind:key="k">
                  <td>{{data.evaluasi_analisis_kinerja}}</td>
                </tr>
              </template>
              <tr class="blue-grey lighten-5 font-weight-medium"><td>Triwulan III</td></tr>
              <template v-for="(data,k) in data_kesenjangan_kinerja.triwulan_3.skp" :load="log(data)">
                <tr v-bind:key="k">
                  <td>{{data.evaluasi_analisis_kinerja}}</td>
                </tr>
              </template>
              <tr class="blue-grey lighten-5 font-weight-medium"><td>Triwulan IV</td></tr>
              <template v-for="(data,k) in data_kesenjangan_kinerja.triwulan_4.skp" :load="log(data)">
                <tr v-bind:key="k">
                  <td>{{data.evaluasi_analisis_kinerja}}</td>
                </tr>
              </template>
            </tbody>
          </template>
        </v-simple-table>

        <!-- <tr><td>{{coachingText}}</td></tr> -->
        <!-- <v-simple-table class="mt-4 elevation-1">
          <template v-slot:default>
            <tbody>
              <tr class="blue-grey lighten-4 font-weight-bold"><td>Coaching</td></tr>
              <tr><td>Data masih kosong. Masih dalam tahap pengembangan fungsionalitas sistem.</td></tr>
            </tbody>
          </template>
        </v-simple-table>

        <v-simple-table class="mt-4 elevation-1">
          <template v-slot:default>
            <tbody>
              <tr class="blue-grey lighten-4 font-weight-bold"><td>Mentoring</td></tr>
              <tr><td>Data masih kosong. Masih dalam tahap pengembangan fungsionalitas sistem.</td></tr>
            </tbody>
          </template>
        </v-simple-table> -->
      </v-col>
    </v-row>
    <!-- <h4 class="text-h4">Nilai Akhir</h4>
    <v-row justify="center">
      <v-col cols="8">
        <div id="analisa-pie-chart" style="height:300px"></div>
      </v-col>
    </v-row> -->
    <v-simple-table class="mt-4 elevation-1">
      <template v-slot:default>
        <tbody>
          <tr class="blue-grey lighten-4 font-weight-bold"><td>Nilai Akhir</td></tr>
          <tr>
            <td>
              <div id="analisa-pie-chart" style="height:300px"></div>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-simple-table class="mt-4 elevation-1">
      <template v-slot:default>
        <thead>
          <tr class="blue-grey lighten-4 font-weight-bold" align="center">
            <td colspan=12>Summary Gap Kinerja</td>
          </tr>
          <tr class="blue-grey lighten-4 font-weight-medium" align="center">
            <td colspan=3>Triwulan I</td>
            <td colspan=3>Triwulan II</td>
            <td colspan=3>Triwulan III</td>
            <td colspan=3>Triwulan IV</td>
          </tr>
          <tr class="blue-grey lighten-4 font-weight-medium">
            <td>Kinerja</td>
            <td>Perilaku Kerja</td>
            <td>Nilai Akhir</td>
            <td>Kinerja</td>
            <td>Perilaku Kerja</td>
            <td>Nilai Akhir</td>
            <td>Kinerja</td>
            <td>Perilaku Kerja</td>
            <td>Nilai Akhir</td>
            <td>Kinerja</td>
            <td>Perilaku Kerja</td>
            <td>Nilai Akhir</td>
          </tr>
        </thead>
        <tbody>
          <template v-for="(data,k) in data_kesenjangan_kinerja.summary_target_realisasi" :load="log(data)">
            <tr v-bind:key="k" class="blue-grey lighten-5 font-weight-medium" v-if="k == 1">
              <td>{{data.kinerja_1.toFixed(2)}}</td>
              <td>{{data.perilaku_kerja_1.toFixed(2)}}</td>
              <td>{{data.nilai_akhir_1.toFixed(2)}}</td>

              <td>{{data.kinerja_2.toFixed(2)}}</td>
              <td>{{data.perilaku_kerja_2.toFixed(2)}}</td>
              <td>{{data.nilai_akhir_2.toFixed(2)}}</td>

              <td>{{data.kinerja_3.toFixed(2)}}</td>
              <td>{{data.perilaku_kerja_3.toFixed(2)}}</td>
              <td>{{data.nilai_akhir_3.toFixed(2)}}</td>

              <td>{{data.kinerja_4.toFixed(2)}}</td>
              <td>{{data.perilaku_kerja_4.toFixed(2)}}</td>
              <td>{{data.nilai_akhir_4.toFixed(2)}}</td>
            </tr>
          </template>
        </tbody>
        <tbody>
          <tr class="blue-grey lighten-4 font-weight-bold"><td colspan="12">Gap Kinerja</td></tr>
          <tr><td colspan="12" :class="{'red--text':data_kesenjangan_kinerja.str_gap_kinerja === 'kesenjangan tinggi', 'orange--text text--lighten-3':data_kesenjangan_kinerja.str_gap_kinerja==='kesenjangan sedang', 'yellow--text text--lighten-1':data_kesenjangan_kinerja.str_gap_kinerja === 'kesenjangan rendah','green--text':data_kesenjangan_kinerja.str_gap_kinerja === 'Tidak ada kesenjangan', 'font-weight-medium':true, 'lighten-2':true}">{{data_kesenjangan_kinerja.str_gap_kinerja.toUpperCase()}}.</td></tr>
        </tbody>
      </template>
    </v-simple-table>
    <!-- <h4 class="text-h4">Rekomendasi</h4>
    <v-row justify="center">
      <v-col cols="8">
        <div id="analisa-vertical-chart" style="height:200px"></div>
      </v-col>
    </v-row> -->
    <v-simple-table class="mt-4 elevation-1">
      <template v-slot:default>
        <tbody>
          <tr class="blue-grey lighten-4 font-weight-bold"><td>Nilai Akhir</td></tr>
          <tr>
            <td>
              <div id="analisa-vertical-chart" style="height:200px"></div>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-container>
</template>

<script>
import _ from 'lodash'
import echarts from 'echarts'

export default {
  name:"Analisa",
  props:{
    analisaData: {
      default:function (){return {}},
      type:Object
    }
  },
  data(){
    return{
      gapkinerja:'',
      barchart:null,
      triwulanData: {},
      piechart:null,
      verticalchart:null,
      opini:[],
      nilaiAkhir:0,
      coachingText:'Tidak ada saran',
      mentoringText:'Tidak ada saran',
      isTableShown: false,
      triwulanTitle:'?',
      headersTriwulan:[
        {text:'Sasaran Triwulan', value:'sasaran'},
        {text:'Sasaran Tahunan', value:'sasaran'},
        {text:'Indikator Kinerja', value:'indikator_bulanan'},
        {text:'Target Output', value:'target'},
        {text:'Realisasi Output', value:'realisasi'},
        {text:'Satuan Output', value:'satuan_realisasi'},
        {text:'Waktu', value:'waktu'},
        // {text:'Status SKP', value:'status_skp'}
        // {text:'Sasaran Triwulan', value:'sasaran_triwulan'},
        // {text:'Sasaran Tahunan', value:'sasaran_tahunan'},
        // {text:'Indikator Kinerja', value:'indikator_kinerja'},
        // {text:'Target Output', value:'target_ouput'},
        // {text:'Waktu', value:'waktu'},
        // {text:'Status SKP', value:'status_skp'}
      ],
      itemsTriwulan:[],
      data_kesenjangan_kinerja:[]
    }
  },
  created(){
    console.log('analisa kesenjangan kinerja', this.analisaData.analisa.data)
    this.data_kesenjangan_kinerja = this.analisaData.analisa.data
    this.triwulanData = this.analisaData.analisa.data
  },
  mounted(){
    // inisialisasi grafik yang akan digunakan
    this.triwulan = echarts.init(document.getElementById('analisa-bar-chart'))
    this.piechart = echarts.init(document.getElementById('analisa-pie-chart'))
    this.verticalchart = echarts.init(document.getElementById('analisa-vertical-chart'))
    var that = this
    this.triwulan.on('click', function(params){
      console.log('this.triwulan.onclick.params', params.data.triwulan)
      that.showDetailTriwulan(params.data.name, params.data.triwulan)
    })
    // perhitungan kalulasi triwulan dan tampilkan grafik
    this.kalkulasiTriwulan()

    // tampilkan grafik sisanya
    this.setPieChartOption(0,0,0)
    this.setVerticalChartOption(0)

    // integrasi opini
    this.opini = this.analisaData.dialog
    this.kalkulasiNilaiAkhir(this.analisaData)
  },
  methods:{
    log(item) {
      console.log("data kesenjangan kinerja", item)
    },
    showDetailTriwulan(title, triwulan){
      // tampilkan judul triwulan
      this.triwulanTitle = title

      // tampilkan table
      this.isTableShown = !this.isTableShown

      // tampilkan detail data SKP triwulan
      this.itemsTriwulan = triwulan == 1 ? this.triwulanData.triwulan_1.skp : triwulan == 2 ? this.triwulanData.triwulan_2.skp : triwulan == 3 ? this.triwulanData.triwulan_3.skp : this.triwulanData.triwulan_4.skp
    },
    matchGapKinerja(val){
      if(val <= 61){
        this.gapkinerja = "tinggi"
      }else if(val <= 76){
        this.gapkinerja = "sedang"
      }else if(val < 91){
        this.gapkinerja = "rendah"
      } else {
        this.gapkinerja = "tidak ada kesenjangan"
      }
    },
    kalkulasiTriwulan(){
      // for (let i =0; i < this.analisaData.analisa.length; i++){
      //   this.triwulanData['triwulan'+this.analisaData.analisa[i].triwulan] = Math.floor(this.analisaData.analisa[i]['kinerja individu'] *100)/100
      // }
      // this.triwulan.clear()
      // this.setGaugeChartOption(
      //   this.triwulanData['triwulan1'] || 0,
      //   this.triwulanData['triwulan2'] || 0,
      //   this.triwulanData['triwulan3'] || 0,
      //   this.triwulanData['triwulan4'] || 0
      // )
      this.triwulan.clear()
      this.setGaugeChartOption(
        this.triwulanData.triwulan_1 || 0,
        this.triwulanData.triwulan_2 || 0,
        this.triwulanData.triwulan_3 || 0,
        this.triwulanData.triwulan_4 || 0
      )
    },
    kalkulasiNilaiAkhir(){
      if(this.analisaData.dialog.length){
        // let kinerja = this.analisaData.dialog[0].nilai_kinerja * 60/100
        // let perilaku = this.analisaData.dialog[0].nilai_review_perilaku * 40 /100
        // let nilaiAkhir = kinerja + perilaku
        // nilaiAkhir = Math.floor(nilaiAkhir * 100) /100
        // this.nilaiAkhir = nilaiAkhir
        // // nilai gap
        // this.matchGapKinerja(this.nilaiAkhir)
        // this.setPieChartOption(100-nilaiAkhir, perilaku, kinerja)
        // this.setVerticalChartOption(this.nilaiAkhir)

        let nilai_akhir = this.triwulanData.gap_kinerja
        let perilaku = this.triwulanData.triwulan_1.nilai_perilaku_kinerja
        let kinerja = this.triwulanData.triwulan_1.nilai_akhir
        this.matchGapKinerja(nilai_akhir)
        this.setPieChartOption(nilai_akhir, perilaku, kinerja)
        this.setVerticalChartOption(nilai_akhir)
      }
    },
    setPieChartOption(gap, perilaku, kinerja){
      // konfigurasi grafik nilai akhir
      let option = {
          tooltip: {
              trigger: 'item',
              formatter: '{a} <br/>{b} : {d}%'
          },
          series : [
              {
                  name: 'Nilai Akhir',
                  type: 'pie',
                  // radius: '55%',
                  roseType:'angle',
                  data:[
                      {value:gap, name:'Gap Kinerja'},
                      {value:perilaku, name:'Perilaku'},
                      {value:kinerja, name:'Kinerja'}
                  ],
                  itemStyle: {
                      // shadow size
                      shadowBlur: 200,
                      // horizontal offset of shadow
                      shadowOffsetX: 0,
                      // vertical offset of shadow
                      shadowOffsetY: 0,
                      // shadow color
                      shadowColor: 'rgba(0, 0, 0, 0.5)',
                      emphasis: {
                          shadowBlur: 200,
                          shadowColor: 'rgba(0, 0, 0, 0.5)'
                      }
                  }
              }
          ]
      }
      this.piechart.setOption(option)
    },
    setVerticalChartOption(capaianKinerja){
      // konfigurasi grafik rekomendasi
      let option = {
          tooltip: {
              trigger: 'item',
              axisPointer: {
                  type: 'line'
              }
          },
          grid: {
              left: '3%',
              right: '4%',
              bottom: '3%',
              containLabel: true
          },
          xAxis: {
              type: 'value'
          },
          yAxis: {
              type: 'category',
              data: ['Capaian Kinerja', 'Standar Kinerja']
          },
          series: [{
              name: 'Rekomendasi',
              type: 'bar',
              label: {
                  show: true,
                  position: 'insideRight'
              },
              barWidth:20,
              data: [capaianKinerja,100],
              itemStyle:{
                color: '#02A8B5',
                opacity: 0.6,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
                shadowBlur: 10
              },
              markArea:{
                data:[
                  [{
                      name:'Coaching',
                      itemStyle:{color:'#FD4949',opacity:0.5},
                      xAxis:0
                  },{
                      xAxis:50
                  }],
                  [{
                      name:'Mentoring',
                      itemStyle:{color:'#02B58C',opacity:0.5},
                      xAxis:50
                  },{
                      xAxis:80
                  }],
                  [{
                      name:'Special Assignment',
                      itemStyle:{color:'#4C68DD',opacity:0.5},
                      xAxis:80
                  },{
                      xAxis:100
                  }],
                ]
              }
            },
          ]
      };
      this.verticalchart.setOption(option)
    },
    setGaugeChartOption(triwulan1, triwulan2, triwulan3, triwulan4){
      // konfigurasi triwulan dengan gauge chart
      let option = {
          tooltip: {
              formatter: '{a} <br/>{b} : {c}%'
          },
          series: [
              {
                  name: 'triwulan  I',
                  type: 'gauge',
                  center:['15%', '50%'],
                  startAngle:180,
                  endAngle:45,
                  axisLine: {
                    lineStyle:{
                      width:5
                    }
                  },
                  axisTick:{
                    length:15,
                    lineStyle:{
                      color:'auto'
                    }
                  },
                  splitLine:{
                    length:20,
                    lineStyle:{
                      color:'auto'
                    }
                  },
                  detail: {
                    formatter: '{value}',
                    fontSize:20
                  },
                  // data: [{value: triwulan1, name: 'Triwulan I'}]
                  data: [
                    {
                      value: triwulan1.nilai_akhir.toFixed(2), name: 'Triwulan I', triwulan: 1,
                    }
                  ]
              },
              {
                  name: 'triwulan  II',
                  type: 'gauge',
                  center: ['35%', '50%'],
                  startAngle:180,
                  endAngle:0,
                  axisLine: {
                    lineStyle:{
                      width:5
                    }
                  },
                  axisTick:{
                    length:15,
                    lineStyle:{
                      color:'auto'
                    }
                  },
                  splitLine:{
                    length:20,
                    lineStyle:{
                      color:'auto'
                    }
                  },
                  detail: {
                    formatter: '{value}',
                    fontSize:20
                  },
                  // data: [{value: triwulan2, name: 'Triwulan II'}]
                  data: [
                    {
                      value: triwulan2.nilai_akhir.toFixed(2), name: 'Triwulan II', triwulan: 2,
                    }
                  ]
              },
              {
                  name: 'triwulan  III',
                  type: 'gauge',
                  center: ['63%', '50%'],
                  startAngle:180,
                  endAngle:0,
                  axisLine: {
                    lineStyle:{
                      width:5
                    }
                  },
                  axisTick:{
                    length:15,
                    lineStyle:{
                      color:'auto'
                    }
                  },
                  splitLine:{
                    length:20,
                    lineStyle:{
                      color:'auto'
                    }
                  },
                  detail: {
                    formatter: '{value}',
                    fontSize:20
                  },
                  // data: [{value: triwulan3, name: 'Triwulan III'}]
                  data: [
                    {
                      value: triwulan3.nilai_akhir.toFixed(2), name: 'Triwulan III', triwulan: 3,
                    }
                  ]
              },
              {
                  name: 'triwulan  IV',
                  type: 'gauge',
                  center: ['85%', '50%'],
                  startAngle:140,
                  endAngle:0,
                  axisLine: {
                    lineStyle:{
                      width:5
                    }
                  },
                  axisTick:{
                    length:15,
                    lineStyle:{
                      color:'auto'
                    }
                  },
                  splitLine:{
                    length:20,
                    lineStyle:{
                      color:'auto'
                    }
                  },
                  detail: {
                    formatter: '{value}',
                    fontSize:20
                  },
                  // data: [{value: triwulan4, name: 'Triwulan IV'}]
                  data: [
                    {
                      value: triwulan4.nilai_akhir.toFixed(2), name: 'Triwulan IV', triwulan: 4,
                    }
                  ]
              }
          ]
      };
      this.triwulan.setOption(option)
    }
  }// end methods
}
</script>

<style lang="css" scoped>
</style>
