<template lang="html">
  <v-container>
    <h3 class="text-h3">Target Kinerja</h3>
    <v-row>
      <v-col cols="12">
        <div id="piechart-target" style="height:300px"></div>
      </v-col>
    </v-row>
    <div style="width:max-width:900px;overflow-y:auto">
      <div style="width:1000px">
        <v-data-table :headers="headers" :items="sasaran" class="striped" disable-pagination hide-default-footer></v-data-table>
      </div>
    </div>

  </v-container>
</template>

<script>
import echarts from 'echarts'
import _ from 'lodash'

export default {
  name:"Target",
  props:{
    targetData: {
      type:Object,
      default:function(){return {realisasi_output:0,gap_target_output:0 }}
    },
    detailData: Array
  },
  data(){
    return {
      piechart:null,
      headers:[
        { text:"Sasaran Strategis",
          value:"sasaran_strategis",
          sortable:false
        },{
          text:"Sasaran Tahunan",
          value:"sasaran_kinerja_tahunan",
          sortable:false
        },{
          text:"Sasaran Triwulan",
          value:"sasarantriwulan",
          sortable:false
        },{
          text:"Indikator Kinerja",
          value:"indikator_kinerja",
          sortable:false
        },{
          text:"Target Output",
          value:"target"
        },{
          text:"Realisasi Output",
          value:"realisasi"
        },
      ],
      detailRowspan:{},
      sasaran:[]
    }
  },
  computed:{
    tableData: function(){
      if (!_.isEmpty(this.detailRowspan))
        return this.sasaran
      return []
    }
  },
  watch:{
    targetData: {
      handler(val){
        if( !(_.isEmpty(val)) ){
          this.setPieChart(val.gap, val.realisasi)
        }
      },
      deep:true
    },
    detailData(val){
      if (val){
        this.sasaran = val
        this.calculateRowspan()
      }
    }
  },
  mounted(){
    this.piechart = echarts.init(document.getElementById('piechart-target'))
    // panggilan pertama
    this.setPieChart(
      Math.floor(this.targetData.gap * 100) / 100,
      Math.floor(this.targetData.realisasi * 100) /100
    )
    this.sasaran = this.detailData
    // untuk rowspan
    //TODO: gagal di tunda dulu
    // this.calculateRowspan()
  },
  methods:{
    getMark(key){
      if(this.detailRowspan[key]){
        return this.detailRowspan[key].mark
      }
      return false
    },
    getRowspan(key){
      this.detailRowspan[key].mark = true
      return this.detailRowspan[key].val
    },
    calculateRowspan(){
      let temp={}
      for(let i =0; i < this.detailData.length; i++){
        let tempData = this.detailData[i]
        // calculate sasaran strategis
        if(tempData.sasaran_strategis)
          if (temp[tempData.sasaran_strategis]){
            temp[tempData.sasaran_strategis]['val'] +=1
          } else {
            temp[tempData.sasaran_strategis]= {val:1, mark:false}
          }
        if(tempData.sasaran_tahunan)
          if (temp[tempData.sasaran_tahunan]){
            temp[tempData.sasaran_tahunan]['val'] +=1
          } else {
            temp[tempData.sasaran_tahunan] = {val:1, mark:false}
          }

        if(tempData.sasarantriwulan)
          if (temp[tempData.sasarantriwulan]){
            temp[tempData.sasarantriwulan]['val'] +=1
          } else {
            temp[tempData.sasarantriwulan] = {val:1, mark:false}
          }
      }
      this.detailRowspan = temp
    },
    setPieChart(gap, realisasi){
      this.piechart.setOption( {
          tooltip:{},
          series : [
              {
                  name: 'Target Kinerja',
                  type: 'pie',
                  roseType:'angle',
                  data:[
                      {value:gap, name:'Gap Target Output'},
                      {value:realisasi, name:'Realisasi Output'},
                  ],
              }
          ]
      })
    }
  }
}
</script>

<style lang="css" scoped>
table{
  border-collapse: collapse;
}
td{
  border: 1px solid #000;
}
</style>
